import React from "react";
import { DateTime } from "luxon";
import UpcomingEvent from "components/UpcomingEventPanel/UpcomingEventPanel";

const EventGrid = ({ events, limit }) => {
  const sortedEvents = events
    .filter((event) => event?.bouts?.length !== 0)
    .sort(
      (a, b) =>
        DateTime.fromFormat(a.event_time_start, "yyyy-MM-dd HH") -
        DateTime.fromFormat(b.event_time_start, "yyyy-MM-dd HH"),
    );

  const displayEvents = limit ? sortedEvents.slice(0, limit) : sortedEvents;

  return (
    <div className="grid grid-cols-1 gap-10 md:grid-cols-2 md:gap-5 xl:grid-cols-3 2xl:grid-cols-4">
      {displayEvents.map(({
        event_name,
        event_time_start,
        event_location,
        slug,
        bouts,
        thumbnail,
        event_data,
      }, i) => {
        const firstEvent = bouts[0] ?? {};
        const {
          fighter_a_detail: {
            fighter_data: { name_l: fighterA = "Unknown" } = {},
          } = {},
          fighter_b_detail: {
            fighter_data: { name_l: fighterB = "Unknown" } = {},
          } = {},
          weight_class: weightClass,
        } = firstEvent;

        const isPending = event_data?.status === "pending";

        return (
          <UpcomingEvent
            key={i}
            imgBanner={`${thumbnail?.rend1?.src}`}
            href={isPending ? undefined : `event/${slug}`}
            className="w-full min-w-52"
            eventName={event_name}
            eventTime={event_time_start}
            eventLocation={event_location}
            weightClass={weightClass}
            fighterA={fighterA}
            fighterB={fighterB}
            disabled={isPending}
          />
        );
      })}
    </div>
  );
};

export default EventGrid; 