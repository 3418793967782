/* eslint-disable no-nested-ternary */
import React from "react";
import Head from 'next/head';
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import Leaderboard from "components/Leaderboard/Leaderboard";
import UpcomingEvent from "components/UpcomingEventPanel/UpcomingEventPanel";
import LatestArticlePanel from "components/LatestArticlePanel/LatestArticlePanel";
import FeaturePanel from "components/FeaturePanel/FeaturePanel";

import { getServerSidePropsCommon } from "utils/cms";
import Image from "next/image";
import { FeatureBanner } from "utils/images";
import { fetchEvents } from "../api/client/events";
import EventGrid from "components/EventGrid/EventGrid";
import { useRouter } from "next/router";
import { API_URL, ROUTE } from "../const"

const HeroSection = ({ userInfo }) => (
  <>
    <Head>
      {/* HTML Meta Tags */}
      <title>Fight Realm - The place to be for MMA Fans</title>
      <meta name="description" content="Join Fight Realm for UFC fantasy picks and predictions." />

      {/* Facebook Meta Tags */}
      <meta property="og:url" content="https://www.fightrealm.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Fight Realm - The place to be for MMA Fans" />
      <meta property="og:description" content="Join Fight Realm for UFC fantasy picks and predictions." />
      <meta property="og:image" content="https://fr-marketing-assets-271816ae.s3.ap-southeast-2.amazonaws.com/mark24/FightRealmRedBackground.png" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="fightrealm.com" />
      <meta property="twitter:url" content="https://www.fightrealm.com" />
      <meta name="twitter:title" content="Fight Realm - The place to be for MMA Fans" />
      <meta name="twitter:description" content="Join Fight Realm for UFC fantasy picks and predictions." />
      <meta name="twitter:image" content="https://fr-marketing-assets-271816ae.s3.ap-southeast-2.amazonaws.com/mark24/FightRealmRedBackground.png" />
    </Head>
    <div className="relative bg-[url('/img/Fight-Realm-Banner.jpg')] bg-cover bg-center">
      <div className="container relative py-20 md:py-56">
        <div className="relative z-20 flex max-w-screen-md flex-col items-start gap-5 md:text-start">
          <p className="font-archivo ml-1 text-main-red md:text-xl">
            THE ULTIMATE FIGHT SPORT EXPERIENCE
          </p>
          <h1 className="font-anton pr-12 text-5xl leading-[1.1] text-white md:pr-0 md:text-[5.3rem] md:leading-[1.1]">
            STEP INTO THE CAGE{" "}
            <span className="text-main-red">PICK PLAY WIN</span>
          </h1>
          <p className="font-archivo mb-4 max-w-screen-sm text-gray-400 md:text-lg">
            Test your fight IQ, compete and promote to rise the ranks and win amazing prizes! Join the current competition below!
          </p>
          <div className="flex w-full flex-col gap-3 md:flex-row">
            <Link
              href={ROUTE.CURRENT_COMP}
              className="flex items-center justify-center gap-3 rounded-md bg-accent px-6 py-3.5 font-semibold uppercase transition"
            >
              Join FR001 - The Launch
              <FontAwesomeIcon icon={faArrowRight} className="text-base" />
            </Link>
            <Link
              href={ROUTE.CURRENT_EVENT}
              // onClick={(e) => {
              //   e.preventDefault();
              //   const targetElement = document.getElementById("more-info");
              //   const yOffset = -100; // Offset by 77px upwards
              //   const yPosition =
              //     targetElement.getBoundingClientRect().top +
              //     window.pageYOffset +
              //     yOffset;
              //   window.scrollTo({ top: yPosition, behavior: "smooth" });
              // }}
              className="flex items-center justify-center gap-3 rounded-md px-6 py-3.5 font-semibold uppercase text-white transition hover:bg-gray-800/10"
            >
              MAKE YOUR SELECTIONS
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
);

const UpcomingEventsSection = ({ events }) => (
  <div>
    <div className="mt-16 flex items-center justify-between">
      <h2 className="font-header text-3xl uppercase text-white md:text-5xl">
        Upcoming <span className="text-main-red">events</span>
      </h2>
      <Link
        className="hidden rounded-md border bg-[#1C202A] px-6 py-3.5 text-sm font-medium text-white transition hover:bg-gray-800 md:flex md:items-center md:justify-center md:gap-3"
        href="/events"
      >
        All upcoming events
        <FontAwesomeIcon icon={faArrowRight} className="text-base" />
      </Link>
    </div>
    <div className="py-12">
      <EventGrid events={events} limit={4} />
    </div>
    <Link
      className="mt-10 flex w-full items-center justify-center gap-3 rounded-md border bg-[#1C202A] px-6 py-3.5 text-sm font-medium text-white transition hover:bg-gray-800 md:hidden md:items-center md:justify-center md:gap-3"
      href="/events"
    >
      All upcoming events
      <FontAwesomeIcon icon={faArrowRight} className="text-base" />
    </Link>
  </div>
);

const LeaderboardSection = ({ leaderboardData }) => {
  const getFilteredData = () =>
    leaderboardData
      ?.map((row) => ({
        rank: row.season_rank || 0,
        lastRank: row.season_rank_trend?.length > 0
          ? row.season_rank_trend[row.season_rank_trend.length - 1]
          : 0,
        user: row.username,
        pf: row.profile_image,
        lastScore: row.season_score_trend?.length > 0
          ? row.season_score_trend[row.season_score_trend.length - 1]
          : 0,
        totalScore: row.season_score || 0,
      }))
      .sort((a, b) => a.rank - b.rank)
      .slice(0, 5);

  return (
    <div className="mt-10 w-full overflow-y-auto border-t-4 border-solid border-gray-900 bg-gradient-to-b from-[rgba(20,38,63,0.43)_0%] to-[rgba(3,17,25,0.43)_100%] pb-10 pt-4">
      <div className="container">
        <Leaderboard
          leaderboard={getFilteredData()}
        />
        <Link
          href="/leaderboard"
          className="mt-10 flex w-full items-center justify-center gap-3 rounded-md border bg-[#1C202A] px-6 py-3.5 text-sm font-medium text-white transition hover:bg-gray-800 md:items-center md:justify-center md:gap-3"
        >
          View full leaderboard
          <FontAwesomeIcon icon={faArrowRight} className="text-base" />
        </Link>
      </div>
    </div>
  );
};

const ArticlesSection = ({ pinned, articles }) => (
  <div className="container py-12 pt-6">
    <div className="mb-16 flex items-center justify-between md:mt-16">
      <h2 className="font-header text-3xl uppercase text-white md:text-5xl">
        Latest <span className="text-main-red">articles</span>
      </h2>
      <Link
        className="hidden rounded-md border bg-[#1C202A] px-6 py-3.5 text-sm font-medium text-white transition hover:bg-gray-800 md:flex md:items-center md:justify-center md:gap-3"
        href="/articles"
      >
        All articles
        <FontAwesomeIcon icon={faArrowRight} className="text-base" />
      </Link>
    </div>
    <div className="mb-10 grid grid-cols-1 flex-col items-stretch gap-5 md:grid-cols-2 lg:flex-row">
      <LatestArticlePanel
        pinned
        article={pinned?.[0]}
        image={pinned?.[0]?.coverImage?.rend2?.src || null}
        snippet={pinned?.[0]?.snippet}
        title={pinned?.[0]?.title}
        url={pinned?.[0]?.url}
      />
      <LatestArticlePanel
        pinned
        article={pinned?.[1] || pinned?.[0]}
        image={
          pinned?.[1]?.coverImage?.rend2?.src ||
          pinned?.[0]?.coverImage?.rend2?.src ||
          null
        }
        snippet={pinned?.[1]?.snippet || pinned?.[0]?.snippet}
        title={pinned?.[1]?.title || pinned?.[0]?.title}
        url={pinned?.[1]?.url || pinned?.[0]?.url}
      />
    </div>
    <div className="h-full overflow-x-auto">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 lg:justify-normal">
        {articles.slice(0, 4).map((article, i) => (
          <LatestArticlePanel
            key={i}
            article={article}
            image={article?.coverImage?.rend2?.src || null}
            snippet={article?.snippet}
            title={article?.title}
            url={article?.url}
            className={"min-w-52"}
          />
        ))}
      </div>
    </div>
    <Link
      className="mt-10 flex w-full items-center justify-center gap-3 rounded-md border bg-[#1C202A] px-6 py-3.5 text-sm font-medium text-white transition hover:bg-gray-800 md:hidden md:items-center md:justify-center md:gap-3"
      href="/articles"
    >
      All articles
      <FontAwesomeIcon icon={faArrowRight} className="text-base" />
    </Link>
  </div>
);

const FeaturesSection = () => (
  <div className="container my-20" id="more-info">
    <div className="flex w-full flex-col lg:flex-row lg:justify-center lg:gap-10">
      <div className="w-full lg:items-start lg:text-start">
        <span className="inline-block rounded-3xl border border-solid bg-darkBg-700 bg-gradient-to-r from-[#CFFF3F_19.46%] to-[#F0595E_105.17%] p-px text-sm text-white">
          <div className="rounded-[calc(1.5rem-1px)] bg-darkBg-700 px-4 py-1">
            FEATURES
          </div>
        </span>
        <p className="mt-5 font-header text-5xl font-medium text-white">
          THE FUTURE OF <span className="text-5xl text-red-500">FIGHT SPORTS
          </span>
        </p>
        <p className="mt-5 text-gray-400 lg:mb-5">
          Join Fight Realm, the place to be for Fight Sport fans.
        </p>
        <div className="mt-10 flex flex-col gap-3 lg:w-full">
          <FeaturePanel
            buttonContent="Make your picks"
            content="Test your fight IQ and accurately predict the outcomes of upcoming bouts in your favourite fight promotions.  Earn points and rise the leaderboard ranks to win exciting prizes and prove your skill."
            header="Fantasy League"
            buttonHref={ROUTE.CURRENT_EVENT}
          />
          <FeaturePanel
            buttonContent="Check it out"
            content="Get involved with Fight Realm by inviting friends, posting content (coming soon) and promoting the realm to earn points and win prizes.  Check it out."
            header="The Realm"
            buttonHref={ROUTE.CURRENT_COMP}
          />
          <FeaturePanel
            buttonContent="Join here"
            content="We're all about growing the fight sport community, join us on your favourite platform and lets punch on."
            header="Join the community"
            buttonHref={ROUTE.WHATSAPP}
          />
        </div>
      </div>
      <Image
        src={FeatureBanner}
        alt="feature_banner"
        className="hidden self-start gradient-mask-b-70 lg:block"
      />
    </div>
  </div>
);

export default function LandingPage({
  eventData,
  leaderboardData,
  heroImageRend = { rend2: { src: null } },
  pinned,
  userInfo,
  articles,
}) {
  return (
    <div className="body">
      <HeroSection rend2={heroImageRend.rend2} userInfo={userInfo} />
      <div className="container relative">
        <UpcomingEventsSection events={eventData} />
      </div>
      <LeaderboardSection leaderboardData={leaderboardData} />
      <ArticlesSection pinned={pinned} articles={articles} />
      <FeaturesSection />
    </div>
  );
}

export async function getServerSideProps({ req, params, res }) {
  const {
    props: { componentProps },
  } = await getServerSidePropsCommon({ req, params, res });

  try {
    const eventData = await fetchEvents();
    const { data: leaderboardData } = await axios.get(`${API_URL}/v1/leaderboard/`, {
      headers: { "Content-Type": "application/json" },
    });

    return {
      props: {
        eventData,
        leaderboardData,
        ...componentProps,
      },
    };
  } catch (err) {
    console.error(err);
    throw err;
  }
}
