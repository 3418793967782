import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { DateTime } from "luxon";
import VsIcon from "../../public/img/vs.svg";
import Button from "../common/ButtonV2/Button";

const UpcomingPanel = ({
  className,
  eventName,
  eventTime,
  eventLocation,
  imgBanner,
  fighterA,
  fighterB,
  href,
  disabled,
}) => {
  const [displayDate, setDisplayDate] = useState("");


  // Validate and process the date

  const calculateEventStatus = useCallback(() => {
    if (!eventTime) {
      return {
        isValid: false,
        isPast: false,
        isToday: false,
        formattedDate: "Date TBD"
      };
    }

    try {
      const now = DateTime.now();
      const eventDate = DateTime.fromFormat(eventTime, "yyyy-MM-dd HH", {
        zone: "utc",
      }).setZone(now.zone);

      if (!eventDate.isValid) {
        console.error("Invalid date format:", eventDate.invalidReason);
        return {
          isValid: false,
          isPast: false,
          isToday: false,
          formattedDate: "Date TBD"
        };
      }

      const isToday = eventDate.hasSame(now, 'day');

      return {
        isValid: true,
        isPast: eventDate < now && !isToday,
        isToday,
        formattedDate: eventDate.toFormat("EEE, MMM dd yyyy / h:mm a")
      };
    } catch (error) {
      console.error("Error processing date:", error);
      return {
        isValid: false,
        isPast: false,
        isToday: false,
        formattedDate: "Date TBD"
      };
    }
  }, [eventTime]);

  useEffect(() => {
    const { formattedDate } = calculateEventStatus();
    setDisplayDate(formattedDate);
  }, [eventTime, calculateEventStatus]);

  const { isValid, isPast, isToday } = calculateEventStatus();

  const getPinText = () => {
    if (!isValid) return null;
    if (disabled) return "Opening Soon";
    if (isPast) return "Past Event";
    if (isToday) return "Today";
    return null;
  };

  const pinText = getPinText();

  const ContentWrapper = disabled ? 'div' : Link;
  const wrapperProps = disabled ? {
    className: `relative box-border rounded-xl border border-solid bg-gradient-to-r from-[#CFFF3F_19.46%] to-[#F0595E_105.17%] p-1 ${className}`
  } : {
    className: `relative box-border rounded-xl border border-solid bg-gradient-to-r from-[#CFFF3F_19.46%] to-[#F0595E_105.17%] p-1 ${className} cursor-pointer`,
    href
  };

  const pinTextClass = (() => {
    if (isToday) return 'bg-main-green text-dark';
    if (disabled) return 'bg-[#FFD62A] text-dark';
    if (isPast) return 'bg-black/80 text-white';
    return 'bg-black/80 text-white';
  })();

  return (
    <ContentWrapper {...wrapperProps}>
      <div className="relative rounded-[calc(0.75rem-0.25rem)] bg-darkBg-700">
        {pinText && (
          <div className={`absolute right-4 top-4 z-10 rounded-full px-3 py-1 text-sm font-semibold shadow-xl ${pinTextClass}`}>
            {pinText}
          </div>
        )}
        <img
          src={`${imgBanner}`}
          className="h-[500px] w-full"
          style={{ objectFit: "cover" }}
          alt="article-image"
        ></img>
        <div className="absolute bottom-0 flex w-full flex-col items-center gap-2 rounded-[calc(0.75rem-0.25rem)] bg-black bg-opacity-85 pb-14 pt-7">
          <h2 className="font-bebas uppercase text-main-red">{eventName}</h2>
          <div className="flex flex-wrap items-center justify-center gap-2 text-wrap px-10">
            <span className="text-center font-bebas text-4xl uppercase text-white">
              {fighterA}
            </span>
            <VsIcon width={17} height={13} />
            <span className="break-all text-center font-bebas text-4xl uppercase text-white">
              {fighterB}
            </span>
          </div>
          <div className="text-center text-sm font-light text-gray-400">
            <p>{displayDate}</p>
            <p>{eventLocation}</p>
          </div>
          <Button
            type="accent-background-text-black"
            className={`absolute -bottom-4 font-bold ${disabled ? 'cursor-default' : 'cursor-pointer'
              }`}
          >
            Make Selections
          </Button>
        </div>
      </div>
    </ContentWrapper>
  );
};

UpcomingPanel.propTypes = {
  className: PropTypes.string,
  eventName: PropTypes.string.isRequired,
  href: PropTypes.string,
  eventTime: PropTypes.string.isRequired,
  eventLocation: PropTypes.string.isRequired,
  eventBanner: PropTypes.string,
  fighterA: PropTypes.string,
  fighterB: PropTypes.string,
  disabled: PropTypes.bool,
};

UpcomingPanel.defaultProps = {
  className: "",
  disabled: false,
  href: undefined,
};

export default UpcomingPanel;