// utils/images.js

import event1Banner from "public/img/event_1_demo.png";
import event2Banner from "public/img/event_2_demo.png";
import event3Banner from "public/img/event_3_demo.png";
import event4Banner from "public/img/event_4_demo.png";
import featureBanner from "public/img/feature_banner.png";

export const eventBanners = [
  event1Banner,
  event2Banner,
  event3Banner,
  event4Banner,
];

export { featureBanner as FeatureBanner };

// Add more image imports as needed
