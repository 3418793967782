import React, { useState } from "react";
import Image from "next/image";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import Button from "../common/ButtonV2/Button";
import { useRouter } from "next/router";

const FeaturePanel = ({ header, content, buttonContent, buttonHref }) => {
  const [open, setOpen] = useState(false);

  const router = useRouter();


  return (
    <div
      className={`flex flex-col gap-3 p-4 border border-solid border-gray-800 rounded-lg
      ${open ? `bg-[#15181D] text-white` : `bg-darkBg-700 text-gray-500`}`}
    >
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <p className="text-lg font-semibold">{header}</p>
        <FontAwesomeIcon
          icon={open ? faAngleUp : faAngleDown}
          className="text-white"
        />
      </div>
      <Transition
        show={open}
        enter="transition-opacity duration-0"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-0"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex flex-col items-end">
          <p className="text-gray-400">{content}</p>
          <Button
            type="accent-background-text-black"
            className="mt-5 flex items-center gap-2 justify-center"
            onClick={() => router.push(buttonHref)}
          >
            {buttonContent}
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      </Transition>
    </div>
  );
};

FeaturePanel.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonContent: PropTypes.string.isRequired,
  buttonHref: PropTypes.string.isRequired,
};

FeaturePanel.defaultProps = {
  // title: '',
};

export default FeaturePanel;
