import { useQuery } from "@tanstack/react-query";
import { ACCESS_TOKEN, QUERY_KEY } from "../../const";
import apiClient from '../../utils/apiClient';

const PROFILE_ENDPOINT = '/accounts/profile/';

export const profile = async () => {
  const response = await apiClient.makeRequest({
    method: 'GET',
    url: PROFILE_ENDPOINT
  }, {
    context: 'Fetching user profile',
    feature: 'User Profile',
    endpoint: PROFILE_ENDPOINT
  });
  return response;
};

export const useProfile = () => {
  const { status, data, error } = useQuery({
    enabled: typeof window !== "undefined" && !!localStorage.getItem(ACCESS_TOKEN),
    queryKey: [QUERY_KEY.PROFILE],
    queryFn: () => profile(),
    retry: false,
    staleTime: 0,
  });

  return { 
    status, 
    data, 
    error, 
    isAuth: status === "success" 
  };
};

export const updateCurrentUserState = async (data, setUserInfo) => {
  try {
    // Update localStorage first
    localStorage.setItem("currentUser", JSON.stringify(data));

    // Update React state if provided
    if (setUserInfo) {
      setUserInfo(data);
    }

    // Update cookie via API endpoint last
    await apiClient.makeRequest({
      method: 'POST',
      url: '/api/auth/set-token/',
      data: {
        token: localStorage.getItem(ACCESS_TOKEN),
        currentUser: data,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }, {
      context: 'Updating user state',
      feature: 'User Profile',
      endpoint: '/api/auth/set-token',
      metadata: { updateType: 'userState' }
    });
  } catch (error) {
    // Log error but don't throw it
    console.warn('Error updating user state:', error);
    // Don't trigger a full page reload or logout
  }
};

export const changeProfile = async (body, setUserInfo) => {
  try {
    const response = await apiClient.makeRequest({
      method: 'PATCH',
      url: PROFILE_ENDPOINT,
      data: body,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }, {
      context: 'Updating user profile',
      feature: 'User Profile',
      endpoint: PROFILE_ENDPOINT,
      metadata: { updateType: 'profileUpdate' }
    });

    if (response) {
      // Update localStorage and React state only
      localStorage.setItem("currentUser", JSON.stringify(response));
      if (setUserInfo) {
        setUserInfo(response);
      }
    }

    return response;
  } catch (error) {
    console.error('Profile update error:', error);
    throw error;
  }
};

export const removePf = async (setUserInfo) => {
  const response = await apiClient.makeRequest({
    method: 'PATCH',
    url: PROFILE_ENDPOINT,
    data: {
      profile_image: null,
    }
  }, {
    context: 'Removing profile image',
    feature: 'User Profile',
    endpoint: PROFILE_ENDPOINT,
    metadata: { updateType: 'removeProfileImage' }
  });

  if (response) {
    await updateCurrentUserState(response, setUserInfo);
  }

  return response;
};
