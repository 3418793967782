import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { DateTime } from "luxon";
import { PushPin } from "@phosphor-icons/react/dist/ssr";

const LatestArticlePanel = ({
  className,
  pinned = false,
  article,
  image,
  snippet,
  title,
  url = "",
}) => {
  if (!article) return null;

  return (
    <Link
      className={`flex h-full w-full flex-col gap-2 overflow-hidden rounded-3xl bg-white/5 shadow-md shadow-black transition-all hover:bg-white/10 hover:shadow-xl ${className} cursor-pointer`}
      href={url}
    >
      <div className="relative h-[200px] w-full overflow-hidden">
        <img
          src={image}
          className="h-full w-full"
          style={{ objectFit: "cover" }}
          alt="article-image"
        ></img>
        {pinned && (
          <div className="absolute right-4 top-4 flex items-center gap-2 rounded-full bg-white px-3 py-1 text-sm font-semibold shadow-xl">
            <PushPin size={14} weight="fill" /> Pinned
          </div>
        )}
      </div>
      <div className="mx-auto flex flex-grow flex-col justify-between gap-4 p-6">
        <div className="space-y-2">
          <div className="text-xs text-[#94969C]">
            {DateTime.fromFormat(
              article.date.split(" ")[0],
              "yyyy-MM-dd",
            ).toFormat("MMMM dd, yyyy")}
          </div>
          <h3 className="line-clamp-4 font-bebas text-4xl uppercase text-white">
            {title}
          </h3>
        </div>
        <div className="">
          <p className="line-clamp-4 text-sm leading-6 text-[#CECFD2]/50">
            {snippet}
          </p>
          <div className="mt-5 cursor-pointer text-sm text-accent underline">
            Read more
          </div>
        </div>
      </div>
    </Link>
  );
};

LatestArticlePanel.propTypes = {
  className: PropTypes.string,
};

LatestArticlePanel.defaultProps = {
  className: "",
};

export default LatestArticlePanel;
